<template>
    <div>
      <base-navigation></base-navigation>
  <base-card>

  <!-- {{ memberdetails }} -->
  <!-- cases -->
  <!-- {{ membercases }} -->
  
    <h2>Receive payment</h2>
    <form @submit.prevent="receivePayment">
     
      
      <div class="form-control">
      <div class="field">
          <label class="label">Member</label>
          <div class="select">
            
            <select  v-model="formData.member_details">
              <option disabled value="">
                Select Member
              </option>
               <option   v-for="member in memberdetails" :key="member.id">
         {{ member.membership_number  }} {{member.memberNames }}
      </option>
            </select>
          </div>
        </div>
        </div>
         <div class="form-control">
        <label for="amount"> Amount </label>
        <input type="text" id="amount" v-model="formData.amount" />
      </div>
      <div class="form-control">
      <div class="field">
          <label class="label">Case name</label>
          <div class="select">
            
            <select v-model="formData.case_details">
              
              <option   v-for="caze in membercases" :key="caze.id">
         {{ caze.case_id  }} {{caze.Member_name }} {{ caze.Amount_disbursed }} lost {{ caze.Member_name  }} ( {{ caze.Member_name }})
      </option>
    <!-- <option>CASH</option>
    <option>CHEQUE</option> -->
  </select>
          </div>
        </div>
        </div>
  
        <div class="form-control">
        <label for="amount"> Reference </label>
        <input type="text" id="payment_reference" v-model="formData.payment_reference" />
      </div>
       
       
      
      <base-button>Submit</base-button>
    </form>
    </base-card>
  </div>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
      
      
   
    data() {
    
      return {
          user: '',
          
          formData: {
            
              case_details: '',
              member_details:'',
              
              amount: '',
              
              payment_reference: '',
  
              
              
              
              
  
          },
          
       
        
      }
    },
    computed: {
          memberdetails() {
              return  this.$store.getters.members
  
              
          },
          membercases() {
              return  this.$store.getters.allcases
  
              
          },
  
      },
       created() {
      this.$store.dispatch('Loadmembers');  
      this.$store.dispatch('LoadCases');  
      
      
    },
      //  mounted() {
      // let user = localStorage.getItem('user');
      // this.user = JSON.parse(user)
  
      // },
    // methods: {
    //   async createPayment(){
    //     const response = await axios.post("https://api.roberms.com/shangilia/receive/manual/payments", this.formData)
    //     console.log(response)
     
    //       this.$router.replace('/payments');
    //       console.log(this.formData)
  
      
  
    //   },
    // },

    methods: {
   async receivePayment() {
    
    const formIsValid =  this.formData.amount 
    if (formIsValid) {
      const response = await axios.post(
       "https://api.roberms.com/shangilia/record/payment",
       this.formData
     );
     console.log(response);
    //  this.errors.push("Kindly fill all the fields highlighted")
     

     await this.$router.replace("/member/search");
     // console.log(this.formData);

    }
    else {
      this.errors = []
      if (!this.formData.case_id){
        this.errors.push("select case ")
      }
      // if (!this.formData.memberCourt){
      //   this.errors.push("Select your court")
      // }
      // if (!this.formData.member_yob){
      //   this.errors.push("Year of birth is missing")
      // }
      // if (!this.formData.member_Phone){
      //   this.errors.push("Enter your phone number")
      // }
      

      


      console.log("form is invalid are invalid")
    }
     
   }, 
 },
    
  };
  </script>
  
  <style scoped>
  .form-control {
    margin: 0.5rem 0;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  input[type="checkbox"] + label {
    font-weight: normal;
    display: inline;
    margin: 0 0 0 0.5rem;
  }
  
  input,
  textarea {
    display: block;
    width: 100%;
    border: 1px solid #ccc;
    font: inherit;
  }
  
  input:focus,
  textarea:focus {
    background-color: #f0e6fd;
    outline: none;
    border-color: #3d008d;
  }
  
  input[type="checkbox"] {
    display: inline;
    width: auto;
    border: none;
  }
  
  input[type="checkbox"]:focus {
    outline: #3d008d solid 1px;
  }
  
  h3 {
    margin: 0.5rem 0;
    font-size: 1rem;
  }
  
  .invalid label {
    color: red;
  }
  
  .invalid input,
  .invalid textarea {
    border: 1px solid red;
  }
  </style>
  
  
  
      
  
  
  <script>
  
  
  </script>
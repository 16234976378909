<template>
  <div id="body">
    <base-card>
      <div id="element-to-convert">
        <div class="row">
          <div class="col-lg-6">
            <img
              width="120"
              height="100"
              src="../../assets/shangilia_logo.png"
            />
          </div>

          <div class="col-lg-6">
            Shangilia community Welfare
            <br />
            Benevolent welfare for members
            <br />
            Along shangilia road
            <br />
            www.shangiliawelfare.co.ke, shangiliawelfare@gmail.com

            <!-- {{ memberdetails }} -->
          </div>
        </div>
        <small class="float-right">
          <div  v-for="memberdetail in memberdetails" :key="memberdetail.id">
            <b class="text-danger fst-italic"
              >Membership number: {{ memberdetail.membership_number }}
             
            </b>
          </div>
        </small>
        <small class="float-left">
          <div  v-for="memberdetail in memberdetails" :key="memberdetail.id">
            <b class="text-info fst-italic"
              >Registration Date: {{ (memberdetail.Registration_date).slice(0,10)}}
            </b>
          </div>
        </small>
        <br>

       <b class="text-danger">MEMBER DETAILS</b> 

        <div class="row">
          <div class="col-6" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
              MEMBER NAME: {{ memberdetail.memberNames }}
           
          </div>
          <div class="col-6" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
            ID Number: {{ memberdetail.member_idNumber }}
         
        </div>         
       
        </div>
        <br>
        <div class="row">
          <div class="col-6" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
           Phone Number: {{ memberdetail.member_Phone }}
         
        </div> 
        <!-- <div class="col-6" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
            Year of birth: {{ memberdetail.member_yob }}
         
        </div>  -->
       
        </div>
        <br>
        <div class="row">
          <div class="col-6" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          Court: {{ memberdetail.memberCourt }}
         
        </div> 
        <div class="col-6" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
            Gender: {{ memberdetail.memberGender}}
         
        </div> 
       
        </div>
        <br>
        <b class="text-danger">SPOUSE </b> 

        <div class="row">
          <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
       <small>   Name: {{ memberdetail.spouseNames }} </small>
         
        </div> 
        <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         <small>  Id Number: {{ memberdetail.spouse_Idnumber}} </small> 
         
        </div> 
        <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          <small> Phone Number: {{ memberdetail.spouse_phonenumber}} </small> 
         
        </div> 

        
       
        </div>
     

        
        
       



       

        <!-- spouse details

        <div class="row">
          <div class="col">Spouse Name:</div>
          <div class="col">Spouse phone</div>
          <div class="col">Spouse ID</div>
        </div>
        <div class="row">
          <div class="col">.</div>
          <div class="col">{{  }}</div>
          <div class="col">{{  }}</div>
        </div> -->

        <b class="text-danger">CHILDREN</b> 

        <div class="row text-info">
          <div class="col-5">Name:</div>
          <div class="col-4">Gender</div>
          <div class="col-3">Year of Birth</div>
        </div>

        <div class="row">
          
          <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         {{ memberdetail.kidoneNames }}
         
        </div> 
        <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          {{ memberdetail.kidoneGender}}
         
        </div> 
        <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
           {{ memberdetail.kidOne_yob}}
         
        </div> 
        
       
        </div>
       
        <div class="row">
          <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         {{ memberdetail.kidtwoNames }}
         
        </div> 
        <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          {{ memberdetail.kidtwoGender}}
         
        </div> 
        <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
           {{ memberdetail.kidTwo_yob}}
         
        </div> 
        
       
        </div>
       
        <div class="row">
          <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         {{ memberdetail.kidthreeNames }}
         
        </div> 
        <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          {{ memberdetail.kidthreeGender}}
         
        </div> 
        <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
           {{ memberdetail.kidThree_yob}}
         
        </div> 
        
       
        </div>

<div class="row">
          <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         {{ memberdetail.kidfourNames }}
         
        </div> 
        <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          {{ memberdetail.kidfourGender}}
         
        </div> 
        <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
           {{ memberdetail.kidFour_yob}}
         
        </div> 
        
       
        </div>

<div class="row">
          <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         {{ memberdetail.kidfiveNames }}
         
        </div> 
        <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          {{ memberdetail.kidfiveGender}}
         
        </div> 
        <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
           {{ memberdetail.kidFive_yob}}
         
        </div> 
        
       
        </div>

<div class="row">
          <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         {{ memberdetail.kidsixNames }}
         
        </div> 
        <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          {{ memberdetail.kidsixGender}}
         
        </div> 
        <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
           {{ memberdetail.kidSix_yob}}
         
        </div> 
        
       
        </div>
        <div class="row">
          <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         {{ memberdetail.kidsevenNames }}
         
        </div> 
        <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          {{ memberdetail.kidsevenGender}}
         
        </div> 
        <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
           {{ memberdetail.kidSeven_yob}}
         
        </div> 
        
       
        </div>
        <div class="row">
          <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         {{ memberdetail.kideightNames }}
         
        </div> 
        <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          {{ memberdetail.kideightGender}}
         
        </div> 
        <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
           {{ memberdetail.kidEight_yob}}
         
        </div> 
        
       
        </div>

        <b class="text-danger">PARENTS </b> 


       <div class="row">
          <div class="col-7" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
       <small>   Father name: {{ memberdetail.fatherNames }} </small>
         
        </div> 
        <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         <small>  Father ID : {{ memberdetail.father_Idnumber}} </small> 
         
        </div> 
        <!-- <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          <small> YOB: {{ memberdetail.father_yob}} </small> 
         
        </div>  -->
        
       
        </div>

        <div class="row">
          <div class="col-7" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
       <small>   Mother name: {{ memberdetail.motherNames }} </small>
         
        </div> 
        <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         <small>  Mother ID: {{ memberdetail.mother_Idnumber}} </small> 
         
        </div> 
        <!-- <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          <small> YOB: {{ memberdetail.mother_yob}} </small> 
         
        </div>  -->
        
       
        </div>

        <br>
        <div class="row">
          <div class="col-7" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
       <small>   Father Inlaw name: {{ memberdetail.fatherInlaw_names}} </small>
         
        </div> 
        <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         <small>  Father inlaw ID: {{ memberdetail.fatherInlaw_id }} </small> 
         
        </div> 
        <!-- <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          <small> YOB: {{ memberdetail.fatherInlaw_yob}} </small> 
         
        </div>  -->
        
       
        </div>
        <div class="row">
          <div class="col-7" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
       <small>   Mother inlaw name: {{ memberdetail.motherInlaw_names}} </small>
         
        </div> 
        <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
         <small>  Mother inaw ID : {{ memberdetail.motherInlaw_id }} </small> 
         
        </div> 
        <!-- <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
            
          <small> YOB: {{ memberdetail.motherInlaw_yob}} </small> 
         
        </div>  -->
        
       
        </div>
        









      </div>

      <!-- <button class="btn btn-primary" @click="exportToPDF">Download form</button> -->
      <!-- <base-button>Download Form</base-button> -->
    </base-card>
    <button class="btn btn-primary" @click="exportToPDF">Download form</button>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import axios from "axios";

export default {
    methods: {
      async findMember() {
        const response = await axios.get(
          "https://api.roberms.com/shangilia/get/member/id/" + this.memberId
        );
        // console.log(response);
        this.member = response.data;
        this.memberId = response.data.member_idNumber;
      },
      exportToPDF() {
      var element = document.getElementById("element-to-convert");
      html2pdf(element, {
        margin: [5, 15, 10, 15], //top, left, buttom, right
        filename: this.memberdetails[0].memberNames + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          dpi: 192,
          scale: 2,
          logging: true,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      });
    },
      
    },
 
   
  
  created() {
    (this.memberId = this.$route.params.memberId),
      this.$store.dispatch("Loadmembers"); 
  },
  computed: {
    memberdetails() {
        // return this.$store.getters.memberGetter(this.$route.params.memberId); 
        return this.$store.getters.memberdetails(this.memberId); 

        // return this.$store.getters.members; 

      
    },
  },
};
</script>
<style scoped>
#body {
  width: 98vw;
  /* height: 100vw; */
  /* background-color: #eaf8d0c9; */
  position: absolute;
  margin-top: 1px;
  left: 20px;
  z-index: 999;
  justify-content: center;
  align-items: center;
}
</style>

<template>
    <div>
      <base-navigation></base-navigation>
    <base-card>
    
      <h3>register member here</h3>
      <form @submit.prevent="createMember">
        <div >
          <!-- <i class="fas fa-user fa-lg me-3 fa-fw"></i> -->
          <label for="name"> Name </label>
          <input class="form-control" type="text" id="name" v-model="formData.member_name" />
        </div>
        <div >
           
         
          <label for="email">Member Number </label>
          <input class="form-control" type="text" id="email" v-model="formData.member_id" />
        </div>
        <div >
          <!-- <i class="fas fa-user fa-lg me-3 fa-fw"></i> -->
          <label for="name"> Registration Fee </label>
          <input class="form-control" type="text" id="name" v-model="formData.registration_amount" />
        </div>
        <div >
          <!-- <i class="fas fa-user fa-lg me-3 fa-fw"></i> -->
          <label for="name"> Buffer Amount </label>
          <input class="form-control" type="text" id="name" v-model="formData.buffer_amount" />
        </div>
        <div >
          <!-- <i class="fas fa-user fa-lg me-3 fa-fw"></i> -->
          <label for="name"> Buffer Amount reference</label>
          <input class="form-control" type="text" id="name" v-model="formData.reference" />
        </div>
        
       
         
        <button class="btn btn-secondary">Submit</button>
        <!-- <base-button class="btn btn-secondary">Submit</base-button> -->
      </form>
      </base-card>
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    export default {
        
     
      data() {
        return {
            formData: {         
                member_id:'',
                member_name: '',
                registration_amount: '',
                buffer_amount:'',
                reference:'',
            
    
            },
          
        }
      },
      methods: {
        async createMember(){
          const response = await axios.post("https://api.roberms.com/shangilia/record/registration/payment", this.formData)
          console.log(response)
            // axios.post("http://localhost:5000/user/registration", this.formData)
            // .then(response=>console.log(response))
            // .catch(error=>console.log(error))
            this.$router.replace('/member/registration');
            console.log(this.formData)
    
        
    
        },
      },
      
    };
    </script>
    
    <style scoped>
    .form-control {
      margin: 0.5rem 0;
    }
    
    label {
      font-weight: bold;
      display: block;
      margin-bottom: 0.5rem;
    }
    
    input[type="checkbox"] + label {
      font-weight: normal;
      display: inline;
      margin: 0 0 0 0.5rem;
    }
    
    input,
    textarea {
      display: block;
      width: 100%;
      border: 1px solid #ccc;
      font: inherit;
    }
    
    input:focus,
    textarea:focus {
      background-color: #f0e6fd;
      outline: none;
      border-color: #3d008d;
    }
    
    input[type="checkbox"] {
      display: inline;
      width: auto;
      border: none;
    }
    
    input[type="checkbox"]:focus {
      outline: #3d008d solid 1px;
    }
    
    h3 {
      margin: 0.5rem 0;
      font-size: 1rem;
    }
    
    .invalid label {
      color: red;
    }
    
    .invalid input,
    .invalid textarea {
      border: 1px solid red;
    }
    </style>
    
    
    
        
    
    
    <script>
    
    
    </script>
<template>
    <div>
        <base-navigation></base-navigation>
<base-card>
<!-- clients {{ clients }} -->
        <form @submit.prevent="newTrade">
  <div class="form-group">
    <div class="row">
      <div class="col">
        
        <div>
          <label for="name">Item/Case number:</label>
          <input type="text" v-model="formData.item" id="name" required />
        </div>
        

         <div>
          <label for="name">reference (Mpesa/Cheque number)</label>
          <input type="text" v-model="formData.reference" id="name"  />
        </div>

        <div>
          <label for="name">Expense Amount</label>
          <input type="text" v-model="formData.amount" id="c_rate" required />
        </div>

    
        
       

      </div>
      <div class="col">
       

        <div>
          <label for="name">Description:</label>
          <input type="text" v-model="formData.description" id="l_rate" required />
        </div>

       
        
       
       
        <div>
          <label for="name">Paid To:</label>
          <input type="text" v-model="formData.paid_to" id="l_rate" required />
        </div>

        <div>
          
          <label for="registrationDate">Expense Date:</label>
          <input type="date" v-model="formData.expense_date" id="registrationDate" required />
        </div>

</div>

    </div>
   
        
  </div>
  
 
  <button type="submit" class="btn btn-primary">Submit</button>
</form>

</base-card>

   
    <div class="client-registration-form">
   
    </div>

    </div>
</template>


<script>

import axios from 'axios';
  
  export default {
    data() {
      return {
        formData: {
        
          expense_date: '',
          paid_to: '',
          amount:'',
          description:'',
          item:'',
          reference:'',
          
        },
       
      };
    },
    computed: {
        clients() {
            return  this.$store.getters.clients

            
        }

    },
    methods: {
      
    async newTrade() {
      const formIsValid =
        this.formData.amount;
      if (formIsValid) {
        const response = await axios.post(
          "https://api.roberms.com/shangilia/new/expense",
          this.formData
        );
        console.log(response);

        await this.$router.replace("/all/expenses");
        // console.log(this.formData);
      } else {
        this.errors = [];
        if (!this.formData.amount) {
          this.errors.push("Enter Business Name ");
        }
        

        console.log("form is invalid");
      }
    },
      submitForm() {
        // Handle form submission, e.g., send data to an API
        console.log('Form data:', this.form);
        // Reset the form
        this.form.name = '';
        this.form.registrationDate = '';
        this.form.country = '';
      }
    },
    created() {
    // this.$store.dispatch('LoadClients');  
    
    
  },
  };
  </script>

<style scoped>
  
.client-registration-form {
  max-width: 400px;
  margin: auto;
}
form div {
  margin-bottom: 1rem;
}
label {
  display: block;
  margin-bottom: 0.5rem;
}
input, select, button {
  width: 100%;
  padding: 0.5rem;
}
</style>
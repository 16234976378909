

<template>
  <div id="body">
    <base-navigation></base-navigation>

    <base-card>
      <div id="element-to-convert">
          <div class="row">
        <div class="col-lg-6">
          <img
            width="120"
            height="100"
            src="../../assets/shangilia_logo.png"
          />
        </div>

        <div class="col-lg-6">
        <small> Shangilia community Welfare </small> 
          <br />
      <small>   Benevolent welfare for members </small> 
          <br />
      <small>  Along shangilia road </small> 
          <br />
      <small>   www.shangiliawelfare.co.ke, shangiliawelfare@gmail.com </small> 

          <!-- {{ memberdetails }} -->
        </div>
      </div>

        <div class="row">
          <div class="col-lg-7">
            <small>Mpesa payments raw Report</small>
          </div>
          <div class="col-lg-5 text-info" >Date <small> {{ Date().toLocaleString('nl-NL').split("GMT")[0] }}</small></div>
        </div>
        

        <br />

       <!-- payments {{ this.$store.getters.joiningMember(2) }} -->

        
        <table class="table myFormat table-striped table-bordered table-sm">
          <thead>
            <tr>
              <th><small>Member No</small></th>
              <th><small>Paid By</small></th>
              <th><small>Amt.</small></th>
              <th><small>Payment Date.</small></th>
              <th><small>Trans ID.</small></th>
              <th><small>Ref.</small></th>
              <th><small>Processed.</small></th>
             
            </tr>
          </thead>
          <tbody>
            <tr v-for="payment in mpesapayments.slice().reverse()" :key="payment.id">
                    
                  
                    <td><small>{{ payment.account_number }}</small></td>
                    <td><small>{{ payment.FirstName }}</small></td>
                    <td><small>{{ payment.TransAmount}}</small></td>
                    <td><small>{{ new Date(payment.payment_date).toLocaleString('nl-NL') }}</small></td>
                    <td><small>{{ payment.TransID}}</small></td>
                    
                    <td><small>{{ payment.payment_reference}}</small></td>
                    <td><small>{{ payment.processed}}</small></td>
                    
        



            <!-- <tr v-for="member in memberdetails" :key="member.id">
              <td>
                <small> {{ member.membership_number }}</small>
              </td>
              <td>
                <small>{{ member.memberNames.split(" ")[0]  }}</small>
              </td>

              <td>
                <small>{{ member.memberCourt }}</small>
              </td>

              <td>
                <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].registration_amount }}</small>
              </td>
              <td>
                <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].buffer_amount }}</small>
              </td>
              
              <td>
                <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_1_amount }}</small>

              </td>


              <td>
                  
                <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_2_amount }}</small>
             
              </td>
              <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_3_amount }}</small>
               
                </td>
                <td>
                  
                  <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_4_amount }}</small>
               
                </td>
           

               -->

            </tr>
          </tbody>
        </table>
        <br />
        <br />
       
        <div class="row">
          <br />
        </div>
      </div>

      
      <br>
     
      <button @click="exportToPDF">Download </button>


      <!-- <button @click="exportToPDF">Download</button> -->
      <br />
      <router-link to="/">Go back</router-link>
    </base-card>
  </div>
</template>
<script>
//   import axios from "axios";
import html2pdf from "html2pdf.js";
export default {
    
  methods: {
    exportToPDF() {
      var element = document.getElementById("element-to-convert");
      html2pdf(element, {
        margin: [3, 15, 2, 15], //top, left, buttom, right
        filename: new Date(Date.now() ).toLocaleString('nl-NL').split(",")[0]  + " Mpesa.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          dpi: 192,
          scale: 2,
          logging: true,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      });
    },
  },

   

  computed: {
      memberdetails() {
            return  this.$store.getters.allMembers

            
        },
        mpesapayments() {
            return  this.$store.getters.mpesaPayments

            
        },
        someMembers(){


         return  this.$store.getters.newmembers 
        },
        membercases() {
            return  this.$store.getters.allcases

            
        },
        payments() {
          return  this.$store.getters.allPayments

          
      },
      active_members() {
          return  this.$store.getters.newmembers.filter((col) => col.buffer_amount == 2000).filter((col) => col.case_1_amount == 450).filter((col) => col.case_2_amount == 400).filter((col) => col.processed == 1)

          
      },
      
      memberPayments() {
          return  this.$store.getters.payments//.filter((col) => col.member_id == memberId)

          
      },
      total_registrationFee() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.registration_amount), 0);
    },
    total_buffferAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.buffer_amount), 0);
    },
    total_caseOneAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_1_amount), 0);
    },
    total_caseTwoAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_2_amount), 0);
    },
    total_caseThreeAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_3_amount), 0);
    },
    total_caseFourAmount() {
      return this.someMembers
        .reduce((acc, item) => acc + parseInt(item.case_4_amount), 0);
    },
    
      


   
   
  },
  created() {
    this.$store.dispatch("Loadmembers");
    this.$store.dispatch('LoadPayments'); 
    this.$store.dispatch('LoadNewmembers');   
    this.$store.dispatch('LoadMpesapayments'); 
    

  
  },
};
</script>

<style scoped>
table.myFormat tr td { font-size: 12px; }
</style>




<!-- <template>
    <div>
    <base-card>
<table class="table table-striped table-bordered">
        <thead>
            <tr >
                <th><h3>Member Number</h3></th>
                <th><h3>Name</h3></th>
                <th><h3>amount</h3></th>
                <th><h3>Payment date</h3></th>
                <th><h3>Transaction ID</h3></th>
                <th><h3>Reference</h3></th>
                <th><h3>Processed</h3></th>
            </tr>
            </thead> 
            <tbody>
                <tr v-for="payment in payments.slice().reverse()" :key="payment.id">
                    
                  
                    <td>{{ payment.account_number }}</td>
                    <td>{{ payment.FirstName }}</td>
                    <td>{{ payment.TransAmount}}</td>
                    <td>{{ new Date(payment.payment_date).toLocaleString('nl-NL') }}</td>
                    <td>{{ payment.TransID}}</td>
                    
                    <td>{{ payment.payment_reference}}</td>
                    <td>{{ payment.processed}}</td>
                    
                </tr>
            </tbody>
    </table>
    
</base-card>

<button @click="exportToPDF">Download </button>

</div>
</template>
<script>

import html2pdf from "html2pdf.js";


export default {
    methods: {
      exportToPDF() {
        var element = document.getElementById("element-to-convert");
        html2pdf(element, {
          margin: [3, 15, 2, 15], 
          filename: Date.now() + ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            dpi: 192,
            scale: 2,
            logging: true,
            letterRendering: true,
          },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        });
      },
    },
  

    computed: {
        payments() {
            return  this.$store.getters.mpesaPayments

            
        }

    },
     created() {
    this.$store.dispatch('LoadMpesapayments');  
    
    
  },

}

</script> -->

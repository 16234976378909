<template>
  <div>
    <base-navigation></base-navigation>

    <base-card>
      <form @submit.prevent="registerMember">
        <div>
          <div class="parents">
            <strong>FILL IN MEMBER DETAILS FOR MEMBER   </strong> <b class="text-danger">{{ memberids }}</b> 
            <br />
            <small> Fill your details correctly </small>
            <br />
            <div class="form-row"></div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <!-- <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3"> -->

                <input
                  type="text"
                  class="form-control"
                  placeholder="Your names"
                  v-model="formData.memberNames"
                  required
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your ID number"
                  v-model="formData.member_idNumber"
                  required
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <select
                  class="form-control"
                  v-model="formData.memberGender"
                  required
                >
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Your year of Birth"
                  v-model="formData.member_yob"
                  required
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <select class="form-control" v-model="formData.memberCourt" required>
                  <option disabled value="">select Your court</option>
                  <option>Kennindia</option>
                  <option>Swara</option>
                  <option>Tausi</option>
                  <option>Twiga</option>
                  <option>Zebra</option>
                  <option>Pearl</option>
                  <option>Mega</option>
                  <option>Sunshine</option>
                  <option>Rehoboath</option>
                  <option>Simba</option>
                  <option>Serenity</option>
                  <option>Standard</option>
                  <option>Tulivu</option>
                  <option>Pridelands</option>
                  <option>Paradise</option>
                  <option>Greenvilas</option>
                  <option>SkyView</option>
                  <option>Diaspora</option>
                  


                </select>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone number"
                  v-model="formData.member_Phone"
                />
              </div>
            </div>
            spouse details
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Spouse names"
                  v-model="formData.spouseNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Spouse ID number"
                  v-model="formData.spouse_Idnumber"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="spouse phone number"
                  v-model="formData.spouse_phonenumber"
                />
              </div>
            </div>
            <br />

            <strong>PARENTS DETAILS</strong>
            <br />
            <small> FIll your parents details correctly </small>
            <br />
            <div class="form-row"></div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mother names"
                  v-model="formData.motherNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mother ID number"
                  v-model="formData.mother_Idnumber"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Mother year of birth"
                  v-model="formData.mother_yob"
                />
              </div>
            </div>
            <!-- father details  -->
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Father names"
                  v-model="formData.fatherNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Father ID number"
                  v-model="formData.father_Idnumber"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Father year of birth"
                  v-model="formData.father_yob"
                />
              </div>
            </div>

            Parents inlaw
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mother inlaw names"
                  v-model="formData.motherInlaw_names"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mother inlaw ID number"
                  v-model="formData.motherInlaw_id"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Mother inlaw year of birth"
                  v-model="formData.motherInlaw_yob"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Father Inlaw names"
                  v-model="formData.fatherInlaw_names"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Father Inlaw ID number"
                  v-model="formData.fatherInlaw_id"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Father Inlaw year of birth"
                  v-model="formData.fatherInlaw_yob"
                />
              </div>
            </div>

            <br />

            <strong>CHILDREN DETAILS</strong>
            <br />
            <small> FIll your children details correctly </small>
            <br />
            <div class="form-row"></div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Kid one names"
                  v-model="formData.kidoneNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <select class="form-control" v-model="formData.kidoneGender">
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Kid one year of birth"
                  v-model="formData.kidOne_yob"
                />
              </div>
            </div>
            <!-- father details  -->
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Kid Two names"
                  v-model="formData.kidtwoNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <select class="form-control" v-model="formData.kidtwoGender">
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Kid two year of birth"
                  v-model="formData.kidTwo_yob"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Kid three names"
                  v-model="formData.kidthreeNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <select class="form-control" v-model="formData.kidthreeGender">
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Kid three year of birth"
                  v-model="formData.kidThree_yob"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Kid four names"
                  v-model="formData.kidfourNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <select class="form-control" v-model="formData.kidfourGender">
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Kid four year of birth"
                  v-model="formData.kidFour_yob"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Kid five names"
                  v-model="formData.kidfiveNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <select class="form-control" v-model="formData.kidfiveGender">
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Kid five year of birth"
                  v-model="formData.kidFive_yob"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Kid six names"
                  v-model="formData.kidsixNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <select class="form-control" v-model="formData.kidsixGender">
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Kid six year of birth"
                  v-model="formData.kidSix_yob"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Kid seven names"
                  v-model="formData.kidsevenNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <select class="form-control" v-model="formData.kidsevenGender">
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Kid seven year of birth"
                  v-model="formData.kidSeven_yob"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Kid eight names"
                  v-model="formData.kideightNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <select class="form-control" v-model="formData.kideightGender">
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                type="number"
                  class="form-control"
                  placeholder="Kid eight year of birth"
                  v-model="formData.kidEight_yob"
                />
              </div>
            </div>

            <br />
          </div>
        </div>
        <div class="form-row">
          <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
            <base-button>Submit</base-button>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
            <router-link to="/"> Cancel</router-link>
          </div>
        </div>
      </form>
    </base-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      memberids: this.$route.params.memberid,
      user: "",
      errors: [],

      formData: {
        membershipNo: this.$route.params.memberid,
        memberNames: "",
        member_idNumber: "",
        memberCourt: "",
        memberGender: "",
        member_yob: "",
        member_Phone: "",

        spouseNames: "",
        spouse_Idnumber: "",
        spouse_phonenumber: "",

        motherNames: "",
        mother_Idnumber: "",
        mother_yob: "",
        fatherNames: "",
        father_Idnumber: "",
        father_yob: "",
        motherInlaw_names: "",
        motherInlaw_id: "",
        motherInlaw_yob: "",
        fatherInlaw_names: "",
        fatherInlaw_id: "",
        fatherInlaw_yob: "",

        kidoneNames: "",
        kidoneGender: "",
        kidOne_yob: "",
        kidtwoNames: "",
        kidtwoGender: "",
        kidTwo_yob: "",
        kidthreeNames: "",
        kidthreeGender: "",
        kidThree_yob: "",
        kidfourNames: "",
        kidfourGender: "",
        kidFour_yob: "",
        kidfiveNames: "",
        kidfiveGender: "",
        kidFive_yob: "",
        kidsixNames: "",
        kidsixGender: "",
        kidSix_yob: "",
        kidsevenNames: "",
        kidsevenGender: "",
        kidSeven_yob: "",
        kideightNames: "",
        kideightGender: "",
        kidEight_yob: "",
      },
    };
  },

  methods: {
    async registerMember() {
      const formIsValid =
        this.formData.memberNames &&
        this.formData.member_idNumber &&
        this.formData.member_Phone;
      if (formIsValid) {
        const response = await axios.post(
          "https://api.roberms.com/shangilia/member/registration",
          this.formData
        );
        console.log(response);
        //  this.errors.push("Kindly fill all the fields highlighted")

        await this.$router.replace("/member/search");
        // console.log(this.formData);
      } else {
        this.errors = [];
        if (!this.formData.memberNames) {
          this.errors.push("Enter your name ");
        }
        if (!this.formData.memberCourt) {
          this.errors.push("Select your court");
        }
        if (!this.formData.member_yob) {
          this.errors.push("Year of birth is missing");
        }
        if (!this.formData.member_Phone) {
          this.errors.push("Enter your phone number");
        }

        console.log("form is invalid are invalid");
      }
    },
  },
  watch: {
    needy_status(value) {
      this.needy_status = value;
    },
  },
  computed: {
    memberidz() {
      return this.$router.params.memberid;
    },
    nemisIsValid() {
      return this.formData.student_id;
    },
    nameIsValid() {
      return this.formData.student_names;
    },
    
    // members() {
    //   return this.$store.getters.newmembers; //.filter(process=>process.processed =='0')
    // },
  },
};
</script>

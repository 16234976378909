<template>
    <div id="body">
      <base-card>
     
        <div id="element-to-convert">
          <div class="row">
            <div class="col-lg-6">
              <img
                width="150"
                height="150"
                src="../../assets/shangilia_logo.png"
              />
            </div>
  
            <div class="col-lg-6">
              Shangilia community 
              <br />
              Benevolent welfare for members
              <br />
              Along shangilia road
              <br />
              www.shangiliawelfare.org, admin@shangilia.org
  
              <!-- {{ memberdetails }} -->
            </div>
          </div>
          <small class="float-right">
            <div  v-for="memberdetail in memberdetails" :key="memberdetail.id">
                <b class="text-danger fst-italic"
              >Membership number: {{ memberdetail.membership_number }}
             
            </b>
            </div>
          </small>
          <small class="float-left">
            <div  v-for="memberdetail in memberdetails" :key="memberdetail.id">
              <b class="text-info fst-italic">
                Registration Date: {{ (memberdetail.Registration_date).slice(0,10)}}
                <!-- Registration Date: {{ new Date(memberdetail.Registration_date).toLocaleString('nl-NL') }} -->
  
              </b>
            </div>
          </small>
          <br>
  
          <p class="text-danger">MEMBER DETAILS  </p> 
  
          <div class="row">
            <div class="col-6" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
                MEMBER NAME: {{ memberdetail.memberNames }}
             
            </div>
            <div class="col-6" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
              ID Number: {{ memberdetail.member_idNumber }}
           
          </div>         
         
          </div>
          
          <div class="row">
            <div class="col-6" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
             Phone Number: {{ memberdetail.member_Phone }}
           
          </div> 
          <div class="col-6" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
              Year of birth: {{ memberdetail.member_yob }}
           
          </div> 
         
          </div>
        
          <div class="row">
            <div class="col-6" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            Court: {{ memberdetail.memberCourt }}
           
          </div> 
          <div class="col-6" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
              Gender: {{ memberdetail.memberGender}}
           
          </div> 
         
          </div>
          
         <p class="text-danger">SPOUSE DETAILS </p> 
  
          <div class="row">
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
         <small>   Name: {{ memberdetail.spouseNames }} </small>
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           <small>  Id Number: {{ memberdetail.spouse_Idnumber}} </small> 
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            <small> Phone Number: {{ memberdetail.spouse_phonenumber}} </small> 
           
          </div> 
          
         
          </div>
          
  
          
          <p class="text-danger">PARENTS </p> 
  
  
         <div class="row">
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
         <small>   Father name: {{ memberdetail.fatherNames }} </small>
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           <small>  Father ID Number: {{ memberdetail.father_Idnumber}} </small> 
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            <small> YOB: {{ memberdetail.father_yob}} </small> 
           
          </div> 
          
         
          </div>
  
          <div class="row">
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
         <small>   Mother name: {{ memberdetail.motherNames }} </small>
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           <small>  Mother ID Number: {{ memberdetail.mother_Idnumber}} </small> 
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            <small> YOB: {{ memberdetail.mother_yob}} </small> 
           
          </div> 
          
         
          </div>
  
          <br>
          <div class="row">
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
         <small>   Father Inlaw name: {{ memberdetail.fatherInlaw_names}} </small>
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           <small>  Father inlaw ID Number: {{ memberdetail.fatherInlaw_id }} </small> 
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            <small> YOB: {{ memberdetail.fatherInlaw_yob}} </small> 
           
          </div> 
          
         
          </div>
          <div class="row">
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
         <small>   Mother inlaw name: {{ memberdetail.motherInlaw_names}} </small>
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           <small>  Mother inaw ID Number: {{ memberdetail.motherInlaw_id }} </small> 
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            <small> YOB: {{ memberdetail.motherInlaw_yob}} </small> 
           
          </div> 


          <p class="text-danger">PARENTS </p> 
          
         
          </div>
          
  
  
  
  
  
  
  
         
  
          <!-- spouse details
  
          <div class="row">
            <div class="col">Spouse Name:</div>
            <div class="col">Spouse phone</div>
            <div class="col">Spouse ID</div>
          </div>
          <div class="row">
            <div class="col">.</div>
            <div class="col">{{  }}</div>
            <div class="col">{{  }}</div>
          </div> -->
  
          <p class="text-danger">CHILDREN </p> 
  
          <div class="row">
            <div class="col-5">Name:</div>
            <div class="col-4">Gender</div>
            <div class="col-3">Year of Birth</div>
          </div>
  
          <div class="row">
            
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           {{ memberdetail.kidoneNames }}
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            {{ memberdetail.kidoneGender}}
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
             {{ memberdetail.kidOne_yob}}
           
          </div> 
          
         
          </div>
         
          <div class="row">
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           {{ memberdetail.kidtwoNames }}
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            {{ memberdetail.kidtwoGender}}
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
             {{ memberdetail.kidTwo_yob}}
           
          </div> 
          
         
          </div>
         
          <div class="row">
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           {{ memberdetail.kidthreeNames }}
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            {{ memberdetail.kidthreeGender}}
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
             {{ memberdetail.kidThree_yob}}
           
          </div> 
          
         
          </div>
  
  <div class="row">
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           {{ memberdetail.kidfourNames }}
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            {{ memberdetail.kidfourGender}}
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
             {{ memberdetail.kidFour_yob}}
           
          </div> 
          
         
          </div>
  
  <div class="row">
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           {{ memberdetail.kidfiveNames }}
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            {{ memberdetail.kidfiveGender}}
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
             {{ memberdetail.kidFive_yob}}
           
          </div> 
          
         
          </div>
  
  <div class="row">
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           {{ memberdetail.kidsixNames }}
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            {{ memberdetail.kidsixGender}}
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
             {{ memberdetail.kidSix_yob}}
           
          </div> 
          
         
          </div>
          <div class="row">
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           {{ memberdetail.kidsevenNames }}
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            {{ memberdetail.kidsevenGender}}
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
             {{ memberdetail.kidSeven_yob}}
           
          </div> 
          
         
          </div>
          <div class="row">
            <div class="col-5" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
           {{ memberdetail.kideightNames }}
           
          </div> 
          <div class="col-4" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
            {{ memberdetail.kideightGender}}
           
          </div> 
          <div class="col-3" v-for="memberdetail in memberdetails" :key="memberdetail.id">
              
             {{ memberdetail.kidEight_yob}}
           
          </div> 
          <br>
          <br>
          <br>
          <br>

          

          <br>
          <br>

          

         
       
          




        
        </div>
  
  
  Select The member/dependand for the list below.
  
        </div>

        <form @submit.prevent="registerCase">
            <div class="row">
           
                <div  v-for="memberdetail in memberdetails" :key="memberdetail.id">
                    <select class="form-control" v-model="formData.names" required >
                 <option disabled value="">select One</option>
                 
                 <option>200000: Member: {{ memberdetail.memberNames }}</option>
                 <option v-if = "memberdetail.spouseNames">200000: Spouse: {{ memberdetail.spouseNames }}</option>
                 <option v-if = "memberdetail.motherNames"> 100000: mother: {{ memberdetail.motherNames}}</option>
                 <option v-if = "memberdetail.fatherNames"> 100000: Father: {{ memberdetail.fatherNames }}</option>
                 <option v-if = "memberdetail.motherInlaw_names"> 100000: Mother Inlaw: {{ memberdetail.motherInlaw_names }}</option>
                 <option v-if = "memberdetail.fatherInlaw_names"> 100000: Father Inlaw: {{ memberdetail.fatherInlaw_names }}</option>
                 <option v-if = "memberdetail.kidoneNames"> 200000: Kid: {{ memberdetail.kidoneNames }}</option>
                 <option v-if = "memberdetail.kidtwoNames"> 200000: Kid: {{ memberdetail.kidtwoNames }}</option>
                 <option v-if = "memberdetail.kidthreeNames"> 200000: Kid: {{ memberdetail.kidthreeNames }}</option>
                 <option v-if = "memberdetail.kidfourNames"> 200000: Kid: {{ memberdetail.kidfourNames }}</option>
                 <option v-if = "memberdetail.kidfiveNames"> 200000: Kid: {{ memberdetail.kidfiveNames }}</option>
                 <option v-if = "memberdetail.kidsixNames"> 200000: Kid: {{ memberdetail.kidsixNames }}</option>
                 <option v-if = "memberdetail.kidseven"> 200000: Kid: {{ memberdetail.kidsevenNames }}</option>
                 <option v-if = "memberdetail.kideightNames"> 200000: Kid: {{ memberdetail.kideightNames }}</option>
                
                
               </select>
             </div>
             <!-- <div class="col">
                <select class="form-control" v-model="formData.relationship" required >
                 <option disabled value="">Relationship to the member</option>
                 <option>Member</option>
                <option>Spouse</option>
                <option>Father</option>
                <option>Mother</option>
                <option>Son</option>
                <option>Dauther</option>
                <option>Father Inlaw</option>
                <option>Mother Inlaw</option>
                
               </select>

             </div> -->
             <div class="col">
                <div  class="col text-right"><base-button>Submit</base-button></div>

             </div>
            </div>
            
  
             
            
            
          </form>

  
        <!-- <button class="btn btn-primary" @click="exportToPDF">Download form</button> -->
        <!-- <base-button>Download Form</base-button> -->
      </base-card>
      <!-- <button class="btn btn-primary" @click="exportToPDF">Download form</button> -->
    </div>
  </template>
  
  <script>
//   import html2pdf from "html2pdf.js";
  import axios from "axios";
  
  export default {
    data() {
   return {
     user: "",
     errors:[],
    
     

     formData: {
      names: "",
      memberId: this.$route.params.memberId,
     
    //   relationship:"",
     },
    }
},
    

methods: {
   async registerCase() {
    
    const formIsValid =  this.formData.names
    if (formIsValid) {
      const response = await axios.post(
       "https://api.roberms.com/shangilia/record/case",
       this.formData,
     );
     console.log(response);
    //  this.errors.push("Kindly fill all the fields highlighted")
     

     await this.$router.replace("/member/search");
     console.log(this.formData);

    }
    else {
      this.errors = []
      if (!this.formData.names){
        this.errors.push("Select the dependant ")
      }
         


    }
     
   }, 
 },
   
     
    
    created() {
      (this.memberId = this.$route.params.memberId),
        this.$store.dispatch("Loadmembers"); 
    },
    computed: {
      memberdetails() {
          // return this.$store.getters.memberGetter(this.$route.params.memberId); 
          return this.$store.getters.memberdetails(this.memberId); 
  
          // return this.$store.getters.members; 
  
        
      },
    },
  };
  </script>
  <style scoped>
  #body {
    width: 98vw;
    /* height: 100vw; */
    /* background-color: #eaf8d0c9; */
    position: absolute;
    margin-top: 1px;
    left: 20px;
    z-index: 999;
    justify-content: center;
    align-items: center;
  }
  </style>
  
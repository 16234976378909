<template>
    <div>
     <base-navigation></base-navigation>
      
     <base-card> 
    <div class="form-outline">
  <input type="search" id="form1" class="form-control" v-model="search" placeholder="Start typing your name or nummber here"  aria-label="Search"  />
</div>
</base-card>
    <base-card>

    <!-- members: {{ members }} -->
<table class="table table-striped table-bordered">
        <thead>
            <tr >
                <!-- <th><h3>Client Id</h3></th> -->
                <th><small>Member Number</small></th>
                <th><small> Member Name</small></th>
               
                <!-- <th><small>Amount awarded</small></th> -->
                <th><small>Actions</small></th>
                <!-- <th><h6>Application date</h6></th> -->
            </tr>
            </thead> 
            <tbody>
                <tr v-for="member in filteredMembers.slice(0,2)" :key="member.id">
                    
                    <!-- <td>{{ payment.client_id}}</td> -->
                    <td><small>{{ member.member_id}} </small></td>
                    <td><small>{{ member.member_name}}</small></td>

                    

                    <td ><small><a class="btn btn-primary" :href="'/member/registration/' + member.member_id">Register</a></small></td>
                   
                    
                </tr>
            </tbody>
    </table>
    <small>Already regisred? <a href="/member/search">Click here </a> to download your form</small>

    <br>
    <br>

    <p class="text-danger">Terms and Conditions</p>
<small>1. Fund will only benefit residents along Shangilia rd.</small> 

<small>2. All claims will be subjected to thorough vetting to ascertain the authenticity of the member and beneficiary.</small> 

<small>3. Members need to ensure that at registration the information that they provide is true. False infrmation will lead to rejection of all claims.</small> 

<small> 4. Non residents of Shangilia road are not eligible for registration unless after approval by the committee on extremely convincing reasons e.g. A property owner along Shangilia rd and are in the process of developing the property to become a resident.</small> 

</base-card>

<!-- <router-link to="/receive/payment">Receive payment</router-link> -->
</div>
</template>
<script>
import axios from "axios";


export default {
    data() {
    return {
      search: "",
    };
  },
    computed: {
        members() {
            return  this.$store.getters.newmembers.filter(process=>process.processed =='0')

            
        },
        filteredMembers() {
        return this.members.filter((member)=>member.member_name.toLowerCase().includes(this.search.toLowerCase())| member.member_id.toString().includes(this.search.toLowerCase())).slice(0,5)
      //return this.students.filter((student)=>student.student_name.toLowerCase().includes(this.search.toLowerCase())).slice(0,6)
        
       
    },

    },
    actions: {
        async verify() {
      const response = await axios.post(
        "https://api.roberms.com/cdf/verify/secondary/",
        this.formData.student_id, this.formData.processed
      );
      
      console.log(response);
      console.log(this.formData)

      this.$router.push("/verify/secondary"+"?" + this.student_id);
      console.log(this.student_id);
    },


    },
     created() {
    this.$store.dispatch('LoadNewmembers');  
    
    
  },

}

</script>
<template>
    <div>
     <base-navigation></base-navigation>
      <div class="container">
  
      
  
      <section class="vh-100">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6 text-black">
  
          <!-- <div class="px-5 ms-xl-4">
            <i class="fas fa-crow fa-2x me-3 pt-5 mt-xl-4" style="color: #709085;"></i>
            <span class="h1 fw-bold mb-0">Logo</span>
          </div> -->
  
          <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">
  
            <base-card>

          <!-- members:  {{ memberdetails }} -->
       <small> enter your ID 
      </small>
        <br />
        <br />
        <div v-if="member">
  
        </div>
        
        <form @submit.prevent="findMember">
          <div class="row">
            <div class="small">
              <input
                type="search"
                class="form-control"
                v-model="memberId" v-model.trim="memberId"
              placeholder="Enter ID number" required 
              />
              <br>
            </div>
            <br>
            <br>
            <div class="">
              <input
                type="submit"
                class="btn btn-primary"
                style="display: block; margin: 1 rem auto"
              />
            </div>
          </div>
        </form>
  
        <div v-if="member">
          
            click to download form for
            <router-link
              :to="`/member/form/${member.member_idNumber}`"
            >
              {{ member.memberNames }}
            </router-link>
          
        </div>
        <div v-else>
         <small> No member selected. Enter correct Id number  to search or register as a new member </small>
        </div>
  
        <!-- {{ student }} -->
        <br />
       
      </base-card>
  
          </div>
  
        </div>
        <!-- <div class="col-sm-6 px-0 d-none d-sm-block">
          <img src="../../assets/form.png"
            alt="Login image" class="w-100 vh-100" style="object-fit: cover; object-position: left;">
        </div> -->
      </div>
    </div>
  </section>
  </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        memberId: "",
        member: null,
      };
    },
    methods: {
      async findMember() {
        const response = await axios.get(
          "https://api.roberms.com/shangilia/get/member/id/" + this.memberId
        );
        // console.log(response);
        this.member = response.data;
        this.memberId = response.data.member_idNumber;
      },
      
    },
    created() {
      (this.student_id = this.$route.params.id),
      this.$store.dispatch("Loadmembers");
      console.log(this.memberdetails)
    },
    computed: {
      memberdetails() {
        return this.$store.getters.members;
      },
      
    },

   
  };
  </script>
  